import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';

import NavBar from '../../components/navBar/navBar';
import PatientMenu from './PatientMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { __API_URL__ } from '../../SERVER_URL';

const PatientPhotos = () => {
  const { _id_ } = useParams();
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const role = user_data.role;
  const loaderContext = useContext(LoaderContext);

  const [trailsList, setTrialsList] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [infoData, setInfoData] = useState({});
  const [trialFilter, setTrialFilter] = useState('');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const getTrialsList = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/photos/get_patient_trials/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialsList(res.data);
        if (res.data.length > 0) {
          setTrialFilter(res.data[0].id);
        }
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };
  
  const SinglePatient = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const getVisitData = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);
  
    const url = `${__API_URL__}/api/patient/patient/all/get_visits_data/?patient_id=${_id_}&wrinkles=true` + 
      (trialFilter && trialFilter !== "" ? `&trial_name=${trialFilter}` : '');
    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
      )
      .then((res) => {
        const visits = res.data;
        setVisitData(visits);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  useEffect(() => {
    getTrialsList();
    SinglePatient();
  }, []);

  useEffect(() => {
    getVisitData();
  }, [trialFilter]);

  const routeTo = (id) => {
    navigate('/patient/' + _id_ + '/photos/' + id);
  };
  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="Photos - Session View"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <div className="patient_table patient_photos">
          <PatientMenu active="photos" data={infoData} />
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <Link to={'/patient/' + _id_ + '/photos'}>
                  <button type="button" class="btn btn-primary">
                    Session View
                  </button>
                </Link>
                <Link to={'/patient/' + _id_ + '/gallery'}>
                  <button type="button" class="btn">
                    Gallery View
                  </button>
                </Link>
              </div>
            </div>

            {(role === 'CRO Admin' ||
              role === 'Investigator' ||
              role === 'Sub_investigator' ||
              role === 'Cro_coordinator' ||
              role === 'Data Manager' ||
              role === 'Inv_coordinator' ||
              role === 'Super Admin' ||
              role === 'Reviewer') && (
              <div className="row">
                <div className="col-lg-12">
                <label>Trials</label>
                    <select
                      className="form-control photoFilter"
                      value={trialFilter}
                      onChange={(e) => setTrialFilter(e.target.value)}
                    >
                    <option value="">All Trial Areas</option>
                    <option value={0}>Appiell_Wrinkles</option>
                    {trailsList.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      )
                      )
                      }
                    </select>
                </div>
              </div>
            )}
          </div>
          {loaderContext.loaderCount ? (
            <Loader />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    {role === 'Investigator' ||
                    role === 'Sub_investigator' ||
                    role === 'CRO Admin' ||
                    role === 'Cro_coordinator' ||
                    role === 'Data Manager' ||
                    role === 'Inv_coordinator' ||
                    role === 'Super Admin'
                      ? 'Trial Name'
                      : 'Session Area'}
                  </th>
                  <th>Visit Type</th>

                  {(role === 'Investigator' ||
                    role === 'Sub_investigator' ||
                    role === 'CRO Admin' ||
                    role === 'Cro_coordinator' ||
                    role === 'Data Manager' ||
                    role === 'Inv_coordinator' ||
                    role === 'Super Admin') && (
                    <>
                      <th>Visit Name</th>
                    </>
                  )}
                  {(role === 'Investigator' ||
                    role === 'Sub_investigator' ||
                    role === 'Sub_investigator' ||
                    role === 'CRO Admin' ||
                    role === 'Cro_coordinator' ||
                    role === 'Data Manager' ||
                    role === 'Inv_coordinator' ||
                    role === 'Super Admin') && (
                    <>
                      <th>Visit Open Date</th>
                      <th>Visit Close Date</th>
                      <th>
                        {user_data.role === 'CRO Admin' ||
                        user_data.role === 'Investigator' ||
                        user_data.role === 'Sub_investigator' ||
                        user_data.role === 'Inv_coordinator' ||
                        role === 'Cro_coordinator' ||
                        role === 'Data Manager'
                          ? 'Subject Start Date'
                          : 'Patient Start Date'}
                      </th>
                      <th>
                        {user_data.role === 'CRO Admin' ||
                        user_data.role === 'Investigator' ||
                        user_data.role === 'Sub_investigator' ||
                        user_data.role === 'Inv_coordinator' ||
                        role === 'Cro_coordinator' ||
                        role === 'Data Manager'
                          ? 'Subject Completed Date'
                          : 'Patient Completed Date'}
                      </th>
                      <th>Is Skipped</th>
                      <th>Has Activity?</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {visitData?.map((data) => (
                  <>
                    {data?.visit_session?.map((visit) => {
                      return (
                        <tr
                          key={visit?.id}
                          onClick={
                            visit?.id ? (e) => routeTo(visit?.id) : null
                          }
                        >
                          <td>
                            {visit?.trial_area
                              ? visit?.trial_area
                              : visit?.area?.map((wrinkle) => {
                                  return <>{wrinkle?.name},&nbsp;</>;
                                })}
                          </td>
                          <td>
                            {visit?.visit_type
                              ? visit?.visit_type
                              : 'Regular'}
                          </td>
                          {(role === 'Investigator' ||
                            role === 'Sub_investigator' ||
                            role === 'CRO Admin' ||
                            role === 'Cro_coordinator' ||
                            role === 'Data Manager' ||
                            role === 'Inv_coordinator' ||
                            role === 'Super Admin') && (
                            <>
                              <td>
                                {visit.visit_name
                                  ? visit.visit_name
                                  : visit.visit_type
                                  ? visit.visit_type
                                  : `Visit ${visit.visit_number}`}
                              </td>
                            </>
                          )}
                          <td>
                            {visit.actual_date ? (
                              <>
                                {new Date(
                                  visit.actual_date + 'Z'
                                ).toDateString()}
                                ,&nbsp;
                                {new Date(
                                  visit.actual_date + 'Z'
                                ).toLocaleTimeString()}
                              </>
                            ) : (
                              '-'
                            )}
                          </td>

                          <td>
                            {visit.visit_close_date ? (
                              <>
                                {new Date(
                                  visit.visit_close_date + 'Z'
                                ).toDateString()}
                                ,&nbsp;
                                {new Date(
                                  visit.visit_close_date + 'Z'
                                ).toLocaleTimeString()}
                              </>
                            ) : (
                              '-'
                            )}
                          </td>

                          {!visit?.is_skipped && visit?.start_date ? (
                            <td
                              style={{
                                textAlignLast: 'left',
                                textAlign: 'center',
                              }}
                            >
                              {new Date(
                                visit?.start_date + 'Z'
                              ).toDateString()}
                              ,&nbsp;
                              {new Date(
                                visit?.start_date + 'Z'
                              ).toLocaleTimeString()}
                            </td>
                          ) : (
                            <td
                              style={{
                                textAlignLast: 'center',
                                textAlign: 'center',
                              }}
                            >
                              -
                            </td>
                          )}
                          {visit?.is_skipped ? (
                            <td
                              style={{
                                textAlignLast: 'center',
                                textAlign: 'center',
                              }}
                            >
                              -
                            </td>
                          ) : visit?.completed_date ? (
                            <td
                              style={{
                                textAlignLast: 'left',
                                textAlign: 'center',
                              }}
                            >
                              {new Date(
                                visit?.completed_date + 'Z'
                              ).toDateString()}
                              ,&nbsp;
                              {new Date(
                                visit?.completed_date + 'Z'
                              ).toLocaleTimeString()}
                            </td>
                          ) : (
                            <td
                              style={{
                                textAlignLast: 'center',
                                textAlign: 'center',
                              }}
                            >
                              -
                            </td>
                          )}
                          <td>
                            {visit?.is_skipped
                              ? 'True'
                              : visit?.is_skipped === false
                              ? 'False'
                              : '-'}
                          </td>
                          <td>{visit?.has_activity ? 'True' : 'False'}</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm m-2"
                            >
                              View Case
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientPhotos;
