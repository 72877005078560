import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { keyframes } from "@emotion/react";

const AddVideoTypePopUp = ({
  setShowVideoTypeModal,
  showVideoTypeModal,
  VideoTypeData,
}) => {
  const token = localStorage.getItem("token");
  const [shakeCancel, setShakeCancel] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!title || !image || !description.trim()) {
      toast.error("Please fill all required fields");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);

    setLoading(true);

    try {
      const response = await axios.post(
        `${__API_URL__}/api/photos/add_video_type/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Video type added successfully!");
        resetForm();
        setShowVideoTypeModal(false);
        VideoTypeData();
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (error) {
      console.error("Error saving video type:", error);
      toast.error(error.response?.data?.error || "Failed to save video type");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      setShakeCancel(true);
      setTimeout(() => setShakeCancel(false), 500);
    } else {
      setShowVideoTypeModal(false);
    }
  };

  const shakeAnimation = keyframes`
        0% { transform: translateX(0); }
        20% { transform: translateX(-5px); }
        40% { transform: translateX(5px); }
        60% { transform: translateX(-5px); }
        80% { transform: translateX(5px); }
        100% { transform: translateX(0); }
    `;
  const resetForm = () => {
    setTitle("");
    setDescription("");
    setImage(null);
  };

  return (
    <Modal
      open={showVideoTypeModal}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <h2
          id="modal-title"
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          Add New Video Type
        </h2>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={3}
          margin="dense"
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <InputLabel>Upload Image</InputLabel>
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowVideoTypeModal(false)}
            sx={{
              animation: shakeCancel ? `${shakeAnimation} 0.4s ease` : "none",
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default AddVideoTypePopUp;
