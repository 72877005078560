import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import { Link } from "react-router-dom";
import { Card } from "@mui/material";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { useNavigate, useParams } from "react-router";

const ProofVideos = () => {
  const { _id_ } = useParams();
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [visitData, setVisitData] = useState([]);
  const role = user_data.role;
  const [Loader, setLoader] = useState(true);
  const [infoData, setInfoData] = useState({});
  const navigate = useNavigate();

  const getVideosSession = () => {
    setLoader(true);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/all/get_visits_data/?patient_id=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setVisitData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
        }
      });
  };

  const SinglePatient = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const routeTo = (id) => {
    navigate("/patient/" + _id_ + "/video/" + id);
  };
  
  useEffect(() => {
    getVideosSession();
    SinglePatient();
  }, []);

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs
            B="APP Videos"
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
          ></Breadcrumbs>
          <PatientMenu active="videos" data={infoData} />
          <div className="tableHeader">
            <div className="row"></div>
            {Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <>
                <Card className="col-md-12 my-3">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {role === "Super Admin" ||
                          role === "Investigator" ||
                          user_data.role === "Sub_investigator" ||
                          role === "CRO Admin" ||
                          role === "Cro_coordinator" ||
                          role === "Data Manager" ||
                          role === "Inv_coordinator"
                            ? "Trial Name"
                            : "Session Area"}
                        </th>
                        <th>Visit Type</th>
                        {(role === "Investigator" ||
                          user_data.role === "Sub_investigator" ||
                          role === "CRO Admin" ||
                          role === "Cro_coordinator" ||
                          role === "Data Manager" ||
                          role === "Inv_coordinator" ||
                          role === "Super Admin") && (
                          <>
                            <th>Visit Name</th>
                          </>
                        )}
                        {(role === "Super Admin" ||
                          role === "Investigator" ||
                          user_data.role === "Sub_investigator" ||
                          role === "CRO Admin" ||
                          role === "Cro_coordinator" ||
                          role === "Data Manager" ||
                          role === "Inv_coordinator") && (
                          <>
                            <th>Visit Open Date</th>
                            <th>Visit Close Date</th>
                            <th>
                              {user_data.role === "CRO Admin" ||
                              user_data.role === "Investigator" ||
                              user_data.role === "Sub_investigator" ||
                              user_data.role === "Inv_coordinator" ||
                              role === "Cro_coordinator" ||
                              role === "Data Manager"
                                ? "Subject Start Date"
                                : "Patient Start Date"}
                            </th>
                            <th>
                              {user_data.role === "CRO Admin" ||
                              user_data.role === "Investigator" ||
                              user_data.role === "Sub_investigator" ||
                              user_data.role === "Inv_coordinator" ||
                              role === "Cro_coordinator" ||
                              role === "Data Manager"
                                ? "Subject Completed Date"
                                : "Patient Completed Date"}
                            </th>
                            <th>Is Skipped</th>
                            <th>Has Activity?</th>
                          </>
                        )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {visitData?.map((data) => (
                        <>
                          {data?.visit_session?.map((visit) => {
                            return (
                              <tr
                                key={visit?.id}
                                onClick={
                                  visit?.id ? (e) => routeTo(visit?.id) : null
                                }
                              >
                                <td>{visit?.trial_area}</td>
                                <td>
                                  {visit?.visit_type
                                    ? visit?.visit_type
                                    : "Regular"}
                                </td>
                                {(role === "Investigator" ||
                                  user_data.role === "Sub_investigator" ||
                                  role === "CRO Admin" ||
                                  role === "Cro_coordinator" ||
                                  role === "Data Manager" ||
                                  role === "Inv_coordinator" ||
                                  role === "Super Admin") && (
                                  <>
                                    <td>
                                      {visit.visit_name
                                        ? visit.visit_name
                                        : visit.visit_type
                                        ? visit.visit_type
                                        : `Visit ${visit.visit_number}`}
                                    </td>
                                  </>
                                )}
                                <td>
                                  {visit.actual_date ? (
                                    <>
                                      {new Date(
                                        visit.actual_date + "Z"
                                      ).toDateString()}
                                      ,&nbsp;
                                      {new Date(
                                        visit.actual_date + "Z"
                                      ).toLocaleTimeString()}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {visit.visit_close_date ? (
                                    <>
                                      {new Date(
                                        visit.visit_close_date + "Z"
                                      ).toDateString()}
                                      ,&nbsp;
                                      {new Date(
                                        visit.visit_close_date + "Z"
                                      ).toLocaleTimeString()}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                {visit?.start_date && !visit?.is_skipped ? (
                                  <td
                                    style={{
                                      textAlignLast: "left",
                                      textAlign: "center",
                                    }}
                                  >
                                    {new Date(
                                      visit?.start_date + "Z"
                                    ).toDateString()}
                                    ,&nbsp;
                                    {new Date(
                                      visit?.start_date + "Z"
                                    ).toLocaleTimeString()}
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      textAlignLast: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    -
                                  </td>
                                )}
                                {visit?.is_skipped ? (
                                  <td
                                    style={{
                                      textAlignLast: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    -
                                  </td>
                                ) : visit?.completed_date ? (
                                  <td
                                    style={{
                                      textAlignLast: "left",
                                      textAlign: "center",
                                    }}
                                  >
                                    {new Date(
                                      visit?.completed_date + "Z"
                                    ).toDateString()}
                                    ,&nbsp;
                                    {new Date(
                                      visit?.completed_date + "Z"
                                    ).toLocaleTimeString()}
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      textAlignLast: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    -
                                  </td>
                                )}
                                <td>
                                  {visit?.is_skipped
                                    ? "True"
                                    : visit?.is_skipped === false
                                    ? "False"
                                    : "-"}
                                </td>
                                <td>
                                  {visit?.has_activity ? "True" : "False"}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-sm m-2"
                                  >
                                    View Case
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ))}
                    </tbody>
                  </table>
                </Card>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProofVideos;
