import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import Breadcrumbs from "../../components/breadcrumbs";
import PatientMenu from "../Patient/PatientMenu";
import BasicTabs from "../../components/Trials/Visit_tabs";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import MessageBox from "../../components/Alert/MessageBox";
import { useParams } from "react-router";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useUser from "../../hooks/user";
import Datetime from "react-datetime";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";

const FullForm = () => {
  const defaultVisit = {
    survey_form: "",
    extra_photos: [],
    visit_schedule: 0,
    visit_number: 0,
    consent_form: {
      content: "",
      title: "",
      id: "",
    },
    consent_list_id: "",
    trial_block: [],
    end_visit_schedule: 0,
  };
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const token = localStorage.getItem("token");
  const user = useUser();
  const [numberOfVisits, setNumberOfVisits] = useState(0);
  const [trialId, setTrialId] = useState(0);
  const [trial, setTrial] = useState({});
  const [infoData, setInfoData] = useState({});
  const { _id_ } = useParams();
  const [message, setMessage] = useState("");
  const [typeOfMessage, setTypeOfMessage] = useState("");
  const [modalVisitChangeShow, setModalVisitChangeShow] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [eventList, setEventList] = React.useState([]);
  const [zeroVisit, setZeroVisit] = React.useState(false);
  const [surveyFormBool, setSurveyFormBool] = React.useState(false);
  const [allVisits, setAllVisits] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [numberOfVisitsArray, setNumberOfVisitsArray] = useState([]);
  const [consentFormBool, setConsentFormBool] = React.useState(false);
  const [isEvent, setisEvent] = useState({
    adverse_event: false,
    unscheduled_event: false,
    screening_visit: false,
  });
  const [selectedVisit, setSelectedVisit] = useState(defaultVisit);
  const [selectedSessionId, setSelectedSessionId] = useState(0);
  const [scheduleBaselineModalShow, setScheduleBaselineModalShow] =
    useState(false);
  const [visitStartDateTime, setVisitStartDateTime] = useState(moment());

  useEffect(() => {
    setVisitStartDateTime(moment().startOf("day"));
  }, []);
  const trialDetailApi = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_trial_for_patient/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        res = res.data;
        setTrialId(res.trial.id);
        setTrial(res.trial);
      });
  };

  const patientDetailApi = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const AllVisitsData = (inital_flag) => {
    axios
      .get(
        `${__API_URL__}/api/photos/get_visits/${trialId}/?patient_id=${_id_}&special_visit_sessionId=${selectedSessionId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setAllVisits(res.data);
        const screeningVisitSelected = res.data.filter((visit) => {
          return visit.visit_number == -3;
        });
        const zeroVisitSelected = res.data.filter((visit) => {
          return visit.visit_number == 0;
        });
        setZeroVisit(zeroVisitSelected[0]);
        if (inital_flag) {
          if (res.data.length > 0) {
            let filteredData = res.data.filter((item) =>
              [-1, -2, -3, -4].includes(item.visit_number)
            );
            let visitNumbers = filteredData.map((item) => item.visit_number);
            setEventList(visitNumbers);
            let newNumberOfVisits = res.data.length - (filteredData.length + 1);
            if (newNumberOfVisits < 0) newNumberOfVisits = 0;
            if (
              screeningVisitSelected.length > 0 &&
              zeroVisitSelected.length === 0
            ) {
              setNumberOfVisits(-1);
            } else {
              setNumberOfVisits(newNumberOfVisits);
            }

            if (screeningVisitSelected.length > 0) {
              handleSelectingVisit(screeningVisitSelected[0]);
              setSelectedVisit({
                ...screeningVisitSelected[0],
                start_date: new Date(
                  screeningVisitSelected[0].start_date + "Z"
                ),
              });
            } else if (zeroVisitSelected.length > 0) {
              handleSelectingVisit(zeroVisitSelected[0]);
              setSelectedVisit({
                ...zeroVisitSelected[0],
                start_date: new Date(zeroVisitSelected[0].start_date + "Z"),
              });
            }
          } else {
            setHasChanged(true);
          }
        } else {
          const concernedVisitSelected = res.data.filter((visit) => {
            return visit.visit_number == selectedVisit.visit_number;
          });
          if (concernedVisitSelected.length > 0) {
            setSelectedVisit({
              ...concernedVisitSelected[0],
              start_date: new Date(concernedVisitSelected[0].start_date + "Z"),
            });
          }
        }
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
          user.actions.logout();
        }
      });
  };

  const handleSelectingVisit = (zeroVisit) => {
    if (zeroVisit.consent_form.id) {
      setConsentFormBool(true);
    } else setConsentFormBool(false);
    if (zeroVisit.survey_form.id) {
      setSurveyFormBool(true);
    } else setSurveyFormBool(false);
  };

  useEffect(() => {
    trialDetailApi();
    patientDetailApi();
  }, []);

  const handleBaselineStartDate = () => {
    axios
      .post(
        `${__API_URL__}/api/patient/patient/save_visit_start_date/`,
        {
          visit_start_date: visitStartDateTime,
          patientId: _id_,
          trialId: trialId,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        trialDetailApi();
        toast.success("Start Date Saved Successfully");
        AllVisitsData(true);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  return (
    <>
      <ToastContainer />
      <MessageBox msg={message} type={typeOfMessage} />
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="eCRF"
          F={infoData.first_name}
          L={infoData.last_name}
          E={infoData.email}
          P={infoData.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <div className="patient_table patient_photos">
          <PatientMenu active="ecrf" data={infoData} />
          {trialId !== 0 && (
            <>
              <BasicTabs
                numberOfVisits={numberOfVisits}
                setNumberOfVisits={setNumberOfVisits}
                numberOfVisitsArray={numberOfVisitsArray}
                selectedVisit={selectedVisit}
                setSelectedVisit={setSelectedVisit}
                setMessage={setMessage}
                setTypeOfMessage={setTypeOfMessage}
                AllVisitsData={AllVisitsData}
                trialId={trialId}
                patientId={_id_}
                setValue={setValue}
                value={value}
                zeroVisit={zeroVisit}
                eventList={eventList}
                allVisits={allVisits}
                setNumberOfVisitsArray={setNumberOfVisitsArray}
                handleSelectingVisit={handleSelectingVisit}
                modalVisitChangeShow={modalVisitChangeShow}
                setModalVisitChangeShow={setModalVisitChangeShow}
                hasChanged={hasChanged}
                setHasChanged={setHasChanged}
                patientDetailApi={patientDetailApi}
                selectedSessionId={selectedSessionId}
                setSelectedSessionId={setSelectedSessionId}
                trial={trial}
                setScheduleBaselineModalShow={setScheduleBaselineModalShow}
                trialDetailApi={trialDetailApi}
                patientStatus={infoData.patient_status}
                singlePhotoSession={infoData.single_photo_session}
                setInfoData={setInfoData}
              />
            </>
          )}
        </div>
      </div>
      {modalVisitChangeShow && (
        <>
          <Modal size="md" show onHide={() => setModalVisitChangeShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Unsaved Changes
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <p className="text-center">
                You have Unsaved changes for the current opened Visit!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={(e) => {
                  setHasChanged(false);
                  setModalVisitChangeShow(false);
                }}
              >
                Discard Changes
              </Button>
              <Button
                variant="primary"
                onClick={() => setModalVisitChangeShow(false)}
              >
                Continue Making Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          size="md"
          centered
          show={scheduleBaselineModalShow}
          onHide={() => {
            setScheduleBaselineModalShow(false);
          }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Baseline Visit Start Date
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <div className="col-md-12 calendarIcon">
              <label>
                Start Date - This is the Start Date of the Baseline Visit
              </label>
              <i className="fa fa-calendar-o" aria-hidden="true"></i>

              {trial.enforce_start_date ? (
                <Datetime
                  onChange={(e) => {
                    const formattedDate = moment(e)
                      .startOf("day")
                      .utc()
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                    setVisitStartDateTime(formattedDate);
                  }}
                  value={visitStartDateTime}
                  inputProps={{ placeholder: "dd/mm/yy 00:00 AM" }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                />
              ) : (
                <Datetime
                  onChange={(e) => {
                    setVisitStartDateTime(e);
                  }}
                  value={visitStartDateTime}
                  inputProps={{ placeholder: "dd/mm/yy 00:00 AM" }}
                />
              )}
            </div>
            <div className="col-md-12" style={{ textAlign: "end" }}>
              <button
                type="button"
                className="btn btn-primary btn-sm m-2"
                onClick={() => {
                  handleBaselineStartDate();
                  setScheduleBaselineModalShow(false);
                }}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default FullForm;
