import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../Patient/PatientMenu";
import { useParams } from "react-router";
import Breadcrumbs from "../../components/breadcrumbs";
import Avatar from "../../assets/img/avatar.png";

const VideoDetail = () => {
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const { _id_, caseId } = useParams();
  const token = localStorage.getItem("token");

  const [video, setVideo] = useState([]);
  const [infoData, setInfoData] = useState({});
  const [Loader, setLoader] = useState(true);
  const [selectedTrailAreas, setSelectedTrailAreas] = useState("");
  const [data, setData] = useState({});

  const getSingleVideoList = () => {
    setLoader(true);
    axios
      .get(`${__API_URL__}/api/photos/single_photo_session/${caseId}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        setLoader(false);
        setVideo(res.data.video || []);
        setSelectedTrailAreas(res.data.selected_trail_areas || "N/A");
        setData(res.data);
      })
      .catch(() => setLoader(false));
  };

  const SinglePatient = () => {
    axios
      .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => setInfoData(res.data));
  };

  useEffect(() => {
    getSingleVideoList();
    SinglePatient();
  }, []);

  return (
    <>
      <NavBar active="patient" />
      <div style={{ padding: "20px", background: "#f8f9fa", minHeight: "100vh" }}>
        <Breadcrumbs
          B="Videos"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <PatientMenu active="videos" data={infoData} />

        {Loader ? (
          <div style={{ textAlign: "center", padding: "40px" }}>
            <img
              src="https://i.gifer.com/Yb3A.gif"
              alt="Loading..."
              style={{ width: "80px", height: "80px" }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "10px",
              padding: "10px",
              justifyContent: video.length > 1 ? "space-between" : "center",
            }}
          >
            {video.length > 0 ? (
              video.map((vid, index) => (
                <div
                  key={vid.id}
                  style={{
                    background: "white",
                    borderRadius: "12px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    padding: "8px",
                    textAlign: "center",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    maxWidth: "100%",
                    minWidth: "300px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.03)";
                    e.currentTarget.style.boxShadow = "0 6px 14px rgba(0, 0, 0, 0.15)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  {role === "CRO Admin" || role === "Cro_coordinator" || role === "Data Manager" ? (
                    data.is_hide_video ? (
                      <img
                        src={Avatar}
                        alt="Avatar"
                        style={{
                          width: "61%",
                          borderRadius: "90px",
                          height: "270px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <video
                        controls
                        playsInline
                        src={`${__API_URL__}${vid.video_url}`}
                        style={{
                          width: "100%",
                          borderRadius: "8px",
                          height: "300px",
                          objectFit: "cover",
                        }}
                      />
                    )
                  ) : (
                    <video
                        controls
                        playsInline
                        src={`${__API_URL__}${vid.video_url}`}
                        style={{
                          width: "100%",
                          borderRadius: "8px",
                          height: "300px",
                          objectFit: "cover",
                        }}
                      />
                  )}

                  <div style={{ marginTop: "12px", fontSize: "14px", color: "#444" }}>
                    <p style={{ fontWeight: "bold", color: "#007bff", marginBottom: "8px" }}>
                      {vid.video_type}
                    </p>
                    <p>
                      <strong>Trial Name:</strong> {selectedTrailAreas}
                    </p>
                    <p>
                      <strong>Visit:</strong> {vid.photo_session?.visit_name || "N/A"}
                    </p>
                    <p>
                      <strong>Captured:</strong> {new Date(vid.capture_datetime + "Z").toDateString()}
                    </p>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "#28a745",
                      color: "white",
                      padding: "4px 10px",
                      borderRadius: "8px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    #{index + 1}
                  </div>
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", fontSize: "18px", color: "red" }}>
                No videos attached to this session!
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VideoDetail;