import React from 'react'
import MainAccordion from '../Accordion/accordion'
import MaximumVideoTypes from '../Trials/MaximumVideoTypes'

const MaximumVideoTypesAccordion = ({trialDetails, hasChanged, setHasChanged, setTrialDetails, handleBasicInfoUpdate, setMaximumVideotypes, maximumVideotypes}) => {

  return (
    <MainAccordion
    title="Maximum Video Types"
    data={
        <MaximumVideoTypes 
        trialDetails={trialDetails}
        setTrialDetails={setTrialDetails}
        handleBasicInfoUpdate={handleBasicInfoUpdate}
        maximumVideotypes={maximumVideotypes}
        setMaximumVideotypes={setMaximumVideotypes}
        />}
    />
  )
}
export default MaximumVideoTypesAccordion