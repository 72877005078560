import React from 'react'
import MainAccordion from '../Accordion/accordion'
import VideoLength from '../Trials/VideoLength'

const VideoLengthAccordion = ({trialDetails, setTrialDetails, handleBasicInfoUpdate, setVideoLength, videoLength}) => {

  return (
    <MainAccordion
    title="Video Length"
    data={
        <VideoLength 
        trialDetails={trialDetails}
        setTrialDetails={setTrialDetails}
        handleBasicInfoUpdate={handleBasicInfoUpdate}
        videoLength={videoLength}
        setVideoLength={setVideoLength}
        />}
    />
  )
}
export default VideoLengthAccordion
