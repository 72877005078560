import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useParams } from "react-router";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { toast } from "react-toastify";

const ConsentForm = ({ show, onHide, onSave, AllConsentData }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const token = localStorage.getItem("token");
  
  const handleSave = () => {
    const data = {
    title,
    content,
    };
    axios
      .post(`${__API_URL__}/api/appiell/consent_trial/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        onSave(data); 
        AllConsentData()
        onHide(); 
      })
      .catch(() => {
        toast.error();
      });
  };

  return (
    <Modal open={show} onClose={onHide} aria-labelledby="modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          Add Consent Form
        </Typography>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Consent Form Content"
          multiline
          rows={6}
          variant="outlined"
          fullWidth
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button onClick={onHide} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConsentForm;



