import { React, useState, useEffect } from "react";
import MainAccordion from "../Accordion/accordion";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { toast } from "react-toastify";

const ScheduleVisits = ({
  selectedVisit,
  patientId,
  trialId,
  setScheduleBaselineModalShow,
  AllVisitsData,
  trial,
  patientStatus,
  singlePhotoSession,
  infoData,
  setInfoData
}) => {
  const [showRadioButton, setShowRadioButton] = useState(false);
  const [showScheduleButton, setShowScheduleButton] = useState(false);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const token = localStorage.getItem("token");
  const [statusObject, setStatusObject] = useState({
    visitStartDate: selectedVisit?.actual_date,
    visitCompletedDate: selectedVisit?.completed_date,
    patientStartDate: selectedVisit?.patient_start_date,
    patientCompletedDate: selectedVisit?.patient_completed_date,
    visit_status: selectedVisit?.visit_status || "",
    shouldStartNextVisit: "yes",
  });
  const [updatePatientStatus, setUpdatePatientStatus] = useState(patientStatus);
  const darkGrey = "#A9A9A9";
  const lightGrey = "#F5F5F5";
  const { _id_ } = useParams();
  const handleChange = (status) => {
      setUpdatePatientStatus(status);
      setInfoData(prev => ({
        ...prev,
        patient_status: status, 
      }));};
    useEffect(() => {
      if (infoData?.patient_status) {
          setUpdatePatientStatus(infoData.patient_status);
      }
  }, [infoData]);
  
  useEffect(() => {
    if (
      !(
        selectedVisit.visit_number !== 0 &&
        selectedVisit?.visit_status === "Not Opened"
      )
    ) {
      if (selectedVisit?.patient_start_date) {
        setStatusObject((prev) => {
          return {
            ...prev,
            patientStartDate: moment
              .utc(selectedVisit?.patient_start_date)
              .local()
              .format("YYYY-MM-DDTHH:mm"),
          };
        });
      }
    }

    if (selectedVisit?.patient_completed_date) {
      setStatusObject((prev) => {
        return {
          ...prev,
          patientCompletedDate: moment
            .utc(selectedVisit?.patient_completed_date)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        };
      });
    }

    if (selectedVisit?.actual_date) {
      setStatusObject((prev) => {
        return {
          ...prev,
          visitStartDate: moment
            .utc(selectedVisit?.actual_date)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        };
      });
    }

    if (selectedVisit?.completed_date) {
      setStatusObject((prev) => {
        return {
          ...prev,
          visitCompletedDate: moment
            .utc(selectedVisit?.completed_date)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        };
      });
    }

    if (selectedVisit?.visit_status) {
      setStatusObject((prev) => {
        return {
          ...prev,
          visit_status: selectedVisit?.visit_status,
        };
      });
    }
  }, [selectedVisit]);

  const handleSaveVisitStatus = () => {
    const data = {
      patientId: patientId,
      trialId: trialId,
      visitNumber: selectedVisit?.visit_number,
      status: statusObject.visit_status,
      patientStartDate: moment(
        statusObject.patientStartDate,
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      patientEndDate: moment(
        statusObject.patientCompletedDate,
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      visitOpenDate: moment(
        statusObject?.visitStartDate,
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      
    };

    if (
      statusObject.visit_status === "Completed" &&
      !statusObject.patientCompletedDate
    ) {
      toast.error("Subject Close Date cannot be Empty");
      return;
    }

    if (data.patientStartDate > data.patientEndDate) {
      toast.error("Subject Visit End Date cannot be Past Subject Visit Start Date");
      return;
    }

    if (statusObject.visit_status === "Completed") {
      setShowRadioButton(true);
    } else {
      setShowRadioButton(false);
    }

    if (data.visitNumber !== -3) {
      if (moment(statusObject.visitStartDate) > data.patientStartDate) {
        if (data.visitNumber !== 0) {
          toast.error("Subject Visit Start Date cannot be Past Visit Start Date");
          return;
        }
      } else if (data.patientStartDate > data.patientEndDate) {
        toast.error("Subject Visit End Date cannot be Past Subject Visit Start Date");
        return;
      } else if (
        data.patientEndDate > moment(statusObject.visitCompletedDate)
      ) {
        toast.error("Visit End Date cannot be Past Subject Visit End Date");
        return;
      }
    }

    axios
      .post(`${__API_URL__}/api/photos/change_visit_status/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        toast.success("Visit Status Changed Successfully");
        AllVisitsData();
        if (selectedVisit?.visit_number === -3 && updatePatientStatus === "CONTINUING") {
          setShowScheduleButton(true);
        }
      })
      .catch((err) => {
        toast.error(err.response);
      });

    console.log("_id_: ", _id_);
    const statusData = {
      patient_trial_id: _id_,
      trial_status: updatePatientStatus,
    };

    axios
      .patch(
        `${__API_URL__}/api/patient/patient/update_subject_status/`,
        statusData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .catch((err) => {
        toast.error(err.response);
      });
  };

  return (
    <MainAccordion
      title="Visit Status"
      isOpen={true}
      data={
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10rem",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            {(role === "Investigator" ||
              role === "Inv_coordinator" ||
              role === "Sub_investigator") && (
              <>
                <div className="col-md-12 text-end">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm mx-5"
                    onClick={() => handleSaveVisitStatus()}
                  >
                    Update Status
                  </button>
                </div>
              </>
            )}

            {selectedVisit?.visit_number !== -3 && (
              <>
                <TextField
                  id="filled-multiline-flexible"
                  label="Visit Open Date"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: !(
                        (role === "Investigator" || role === "Inv_coordinator") &&
                        selectedVisit?.visit_number === 0
                      )
                        ? "darkgrey" 
                        : "", 
                    },
                    disabled: !(
                      (role === "Investigator" || role === "Inv_coordinator") &&
                      selectedVisit?.visit_number === 0
                    ),
                  }}
                  type="datetime-local"
                  value={statusObject.visitStartDate}
                  onChange={(event) =>
                    setStatusObject((prev) => ({
                      ...prev,
                      visitStartDate: event.target.value,
                    }))
                  }
                />
                <TextField
                  id="filled-multiline-flexible"
                  label="Visit Close Date"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "darkgrey",
                    },
                  }}
                  type="datetime-local"
                  value={statusObject.visitCompletedDate}
                />
              </>
            )}
            <>
              <TextField
                id="filled-multiline-flexible"
                label="Subject Visit Start Date"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                type="datetime-local"
                value={statusObject.patientStartDate}
                onChange={(event) =>
                  setStatusObject((prev) => {
                    return { ...prev, patientStartDate: event.target.value };
                  })
                }
                disabled={
                  selectedVisit.visit_number !== 0
                    ? selectedVisit?.has_activity
                    : false
                }
              />
              <TextField
                id="filled-multiline-flexible"
                label="Subject Visit End Date"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                type="datetime-local"
                value={statusObject.patientCompletedDate}
                onChange={(event) =>
                  setStatusObject((prev) => {
                    return {
                      ...prev,
                      patientCompletedDate: event.target.value,
                    };
                  })
                }
                disabled={
                  selectedVisit.visit_number !== 0
                    ? selectedVisit?.has_activity
                    : false
                }
              />
            </>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-filled-label">
                Visit Status
              </InputLabel>
              <Select
                value={statusObject.visit_status}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Status"
                onChange={(event) =>
                  setStatusObject((prev) => {
                    return { ...prev, visit_status: event.target.value };
                  })
                }
                disabled={selectedVisit?.has_activity}
                sx={{
                  backgroundColor: 
                    ["Completed", "Skipped"].includes(statusObject.visit_status) ? darkGrey : lightGrey,
                  "& .MuiSelect-select": {
                    backgroundColor: 
                      ["Completed", "Skipped"].includes(statusObject.visit_status) ? darkGrey : lightGrey,
                  },
                }}
              >
                {statusObject.visit_status === "Not Opened" && (
                  <MenuItem value={"Not Opened"}>Not Opened</MenuItem>
                )}
                <MenuItem value={"InComplete"}>InComplete</MenuItem>
                <MenuItem value={"Skipped"}>Skipped</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-filled-label">Subject Status</InputLabel>
              {selectedVisit.visit_number === -3 ? (
                <Select
                  labelId="demo-simple-select-filled-label"
                  value={updatePatientStatus}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <MenuItem value="PRE-SCREENING">PRE-SCREENING</MenuItem>
                  <MenuItem value="SCREEN FAILED">SCREEN FAILED</MenuItem>
                  <MenuItem value="CONTINUING">CONTINUING</MenuItem>
                  <MenuItem value="WITHDREW">WITHDREW</MenuItem>
                </Select>
              ) : selectedVisit.visit_number === 0 ? (
                <Select
                  labelId="demo-simple-select-filled-label"
                  value={updatePatientStatus}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <MenuItem value="CONTINUING">CONTINUING</MenuItem>
                  <MenuItem value="WITHDREW">WITHDREW</MenuItem>
                  <MenuItem value="TERMINATED">TERMINATED</MenuItem>
                  {singlePhotoSession && (
                      <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                  )}
                </Select>
              ) : (
                <>
                <Select
                  labelId="demo-simple-select-filled-label"
                  value={updatePatientStatus}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <MenuItem value="CONTINUING">CONTINUING</MenuItem>
                  <MenuItem value="WITHDREW">WITHDREW</MenuItem>
                  <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                  <MenuItem value="TERMINATED">TERMINATED</MenuItem>
                </Select>
                </>)}
            </FormControl>
          </div>
          {/* DO NOT REMOVE BELOW COMMENT */}
          {!trial.trial_custom_id.includes("RBL") &&
            ["Investigator", "Inv_coordinator", "Sub_investigator"].includes(
              role
              ) &&
              selectedVisit?.visit_number === -3 && showScheduleButton && (              
              <Grid container>
                <Grid item xs={3}>
                  <FormControl
                    sx={{
                      margin: "8px",
                      width: "100%",
                    }}
                  >
                    Would you like to schedule the next visit?
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <div className="">
                    <button
                      onClick={() => setScheduleBaselineModalShow(true)}
                      type="button"
                      className="btn btn-primary btn-sm mx-3 my-2"
                    >
                      Schedule Baseline Visit
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}
        </Box>
      }
    />
  );
};
export default ScheduleVisits;
